import {Badge} from "@mantine/core";
import {AliasStatus} from "../types.ts";

export const AliasStatusBadge = (props: { value: AliasStatus }) => {

    if (typeof props.value == 'undefined' || props.value === null) {
        return <Badge color={'gray'}>Unknown</Badge>
    }

    switch (props.value) {
        case AliasStatus.ENABLED:
            return <Badge color={'blue'}>Enabled</Badge>
        case AliasStatus.DISABLED:
            return <Badge color={'gray'}>Disabled</Badge>
    }

}