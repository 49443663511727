export const listAliases = `
   query listAliases($filter: AliasesFilterInput, $limit: Int, $nextToken: String) {
       listAliases(filter: $filter, limit: $limit, nextToken: $nextToken) {
           items {
              id
              name
              description
              sub
              createdFromPersona
              aliasType
              status
              createdByUsername
              created
              updated
           }
       }
   }
`;

export const getAlias = `
   query getAlias($id:ID!) {
       getAlias(id:$id) {
         id
         name
         description
         sub
         createdFromPersona
         aliasType
         status
         createdByUsername
         created
         updated
       } 
}`;
