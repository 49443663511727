import {useAppDispatch} from '../../../hooks/useTypedReactRedux';
import {
    addTableColumn,
    setTableColumns
} from "../reducers";
import {I18n} from "@aws-amplify/core";
import {
    Alias,
    AliasStatus,
    AliasType
} from "../types.ts";
import {NavLink} from "react-router-dom";
import {
    Badge,
    Group
} from "@mantine/core";
import emailDomains from "../../../config/email-domains.ts";
import {FlexiCopyButton} from "@flexinet/ui-components";
import {formatTimestamp} from "../../../utils/TableTimestamps.tsx";
import {DataTableColumn} from "mantine-datatable";
import {AliasTypeBadge} from "../profile/AliasTypeBadge.tsx";
import {AliasStatusBadge} from "../profile/AliasStatusBadge.tsx";


export const useAliasesTableColumns = () => {

    const dispatch = useAppDispatch();

    const columns: DataTableColumn<Alias>[] = [
        {
            accessor: 'name',
            title: I18n.get('Name'),
            width: '1fr',
            render: (item: Alias) => <NavLink to={`/aliases/${item.id}`}>{item.name}</NavLink>,
        },
        {
            accessor: 'email',
            title: I18n.get('Email Address'),
            width: '1fr',
            sortable: true,
            render: (item: Alias) => <Group>{`${item.name}@${emailDomains.domain}`}<FlexiCopyButton
                value={`${item.name}@${emailDomains.domain}`}/></Group>,

        },
        {
            accessor: 'aliasType',
            title: I18n.get('Alias Type'),
            width: '1fr',
            render: (item: Alias) => {
                return <AliasTypeBadge value={item.aliasType as AliasType}/>
            },
        },
        {
            accessor: 'status',
            title: I18n.get('Status'),
            width: '1fr',
            render: (item: Alias) => {
                return <AliasStatusBadge value={item.status as AliasStatus}/>
            },
        },
        {
            accessor: 'createdFromPersona',
            title: I18n.get('From Persona'),
            width: '1fr',
            render: (item: Alias) => item.createdFromPersona ?
                <Badge variant={'outline'} color={'red'}>yes</Badge> : null
        },
        {
            accessor: 'created',
            title: I18n.get('Created'),
            width: '1fr',
            sortable: true,
            render: (item: Alias) => formatTimestamp(item.created as string),
        },
    ]

    // FETCH
    const handleAddTableColumn = (column: object) => {
        dispatch(addTableColumn(column));
    };

    const handleSetTableColumns = (columns: object[]) => {
        dispatch(setTableColumns(columns));
    };


    return {
        columns,
        handleAddTableColumn,
        handleSetTableColumns,
    };
};
