import {Badge} from "@mantine/core";
import {AliasType} from "../types.ts";

export const AliasTypeBadge = (props: { value: AliasType }) => {

    if (typeof props.value == 'undefined' || props.value === null) {
        return <Badge color={'gray'}>Unknown</Badge>
    }

    switch (props.value) {
        case AliasType.USER:
            return <Badge color={'green'}>User</Badge>
        case AliasType.SYSTEM:
            return <Badge color={'red'}>System</Badge>
    }

}