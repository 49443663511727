import {ITableColumn} from '../../types';
import {ReactNode} from 'react';
import {Tag} from "../tags/types.ts";

export type MessageDimension = { name: string, value: string }

export enum MessageStatus {
    READ = 'READ',
    UNREAD = 'UNREAD',
}

export type Message = {
    id: string,
    messageId?: string,
    fromText?: string,
    fromHtml?: string,
    toText?: string,
    toHtml?: string,
    subject?: string,
    date?: string,
    parsedPath?: string,
    htmlPath?: string,
    htmlObjectKey?: string,
    textPath?: string,
    textAsHtmlPath?: string,
    attachments?: { filename: string, filePath: string, objectKey: string }[],
    status?: MessageStatus,
    tagIds?: string[],
    tags?: { items: Tag[] | null },
    createdByUsername?: string,
    createdBy?: { givenName: string, familyName: string },
    managedByUsername?: string
    managedBy?: { givenName: string, familyName: string },
    created?: string,
    updated?: string,
}


export interface IMessageTableColumn extends ITableColumn {
    render?: (item: Message) => ReactNode;
}

export type ListMessages = {
    listMessages: { items: Message[] }
}

export type GetMessage = {
    getMessage: Message
}

export type CreateMessage = {
    createMessage: Message
}

export type UpdateMessage = {
    updateMessage: Message
}

export type DeleteMessage = {
    deleteMessage: string
}
