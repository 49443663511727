import {
    deleteMessage,
    fetchMessages,
} from '../reducers/recordsSlice';

import {
    recordsSelector,
    selectionSelector,
    visibilitySelector,
} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {useParams} from "react-router-dom";
import {MessageStatus} from "../types.ts";

export const useMessages = () => {

    const {status} = useParams();

    const filter = {} as { tagId?: string, status?: string }
    if (status) {
        if (status === 'inbox') {
            filter.status = MessageStatus.UNREAD
        } else if (status === 'read') {
            filter.status = MessageStatus.READ
        }
    }
    const dispatch = useAppDispatch();

    const {
        items,
        visibleItems,
        visibleCount,
    } =
        useAppSelector((state) => visibilitySelector(state, filter));


    const selection = useAppSelector((state) => selectionSelector(state));

    const {loading} = useAppSelector((state) =>
                                         recordsSelector(state),
    );


    // FETCH
    const fetchRecords = (query: { [k: string]: string }) => {
        dispatch(fetchMessages(query));
    };

    const deleteRecords = () => {
        if (Object.keys(selection).length > 0) {
            for (const key in selection) {

                dispatch(deleteMessage(key));

                // const {
                //     selected,
                //     ...rest
                // } = selection[key];
                // console.debug('remove selected', selected)
                //
                // dispatch(updateMessage({
                //                            id: key,
                //                            record: {...rest, ...{status: 'PENDING_DELETION'}},
                //                        }));

            }
        }
    };


    return {
        items,
        visibleItems,
        visibleCount,
        loading,
        fetchRecords,
        deleteRecords,
    };
};
