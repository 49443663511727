import {Field} from 'formik';

import {
    Box,
    Group,
    Stack
} from '@mantine/core';

import {
    FlexiButton,
    FormikRadioGroup,
    FormikTextarea,
    FormikTextInput,
    PlatformFieldset
} from '@flexinet/ui-components';
import {
    Alias,
    AliasStatus,
    AliasType
} from "../types.ts";
import {AiOutlineReload} from "react-icons/ai";

import classes from './FormComponent.module.css';

export const FormComponent = ({
                                  values,
                                  setFieldValue
                              }: {
    values: Alias & { generationMethod: string },
    setFieldValue: (field: string, value: any) => void
}) => {

    const editing = values.id !== undefined;

    return (
        <Stack gap={'xl'}>
            <PlatformFieldset legend={'Alias'}>

                <Field
                    name='generationMethod'
                    label='Alias Generation Method'
                    description='Please select method'
                    component={FormikRadioGroup}
                    data={[{
                        label: 'Auto',
                        value: 'auto'
                    }, {
                        label: 'Manual',
                        value: 'manual'
                    }]}
                    my={'lg'}
                />

                {values.generationMethod === 'auto' && <Group>

                    <Field
                        name='name'
                        label='Name'
                        description='Generated Name'
                        component={FormikTextInput}
                        classNames={{
                            input: classes.input,
                            label: classes.label,
                            root: classes.root
                        }}
                        my={'lg'}
                    />

                    <Stack mt={'xl'}><Box> </Box><FlexiButton leftSection={<AiOutlineReload/>} onClick={() => {
                        const randomNumber = Math.random() + 1;
                        const name = randomNumber.toString(36)
                                                 .substring(5);
                        console.debug('name', name);
                        setFieldValue('name', name);
                    }}>Generate</FlexiButton>
                    </Stack>
                </Group>}

                {values.generationMethod === 'manual' && <Field
                    name='name'
                    label='Name'
                    description='Please enter the alias. E.g. "john.doe". The email address will be john.doe@flexi.email'
                    component={FormikTextInput}
                    withAsterisk
                    disabled={editing}
                    my={'xl'}
                />}

                <Field
                    name='description'
                    label='Description'
                    description='Please enter description'
                    component={FormikTextarea}
                    my={'xl'}
                />

            </PlatformFieldset>

            <PlatformFieldset legend={'Alias Type'}>
                <Field
                    name='aliasType'
                    label='Alias Type'
                    description='Please enter alias type'
                    data={[{
                        label: 'User',
                        value: AliasType.USER
                    }, {
                        label: 'System',
                        value: AliasType.SYSTEM
                    }]}
                    component={FormikRadioGroup}
                    my={'xl'}
                />
            </PlatformFieldset>

            <PlatformFieldset legend={'Status'}>
                <Field
                    name='status'
                    label='Status'
                    description='Please enter status'
                    data={[{
                        label: 'Enabled',
                        value: AliasStatus.ENABLED
                    }, {
                        label: 'Disabled',
                        value: AliasStatus.DISABLED
                    }]}
                    component={FormikRadioGroup}
                    my={'xl'}
                />
            </PlatformFieldset>


        </Stack>
    );
};
