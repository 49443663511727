import * as Yup from 'yup';
import {
    AliasStatus,
    AliasType
} from "../types.ts";

export const RecordSchema = Yup.object()
                               .shape({
                                          name: Yup.string()
                                                   .required('Required'),
                                          aliasType: Yup.string()
                                                        .oneOf([AliasType.USER, AliasType.SYSTEM])
                                                        .required('Required'),
                                          status: Yup.string()
                                                     .oneOf([AliasStatus.ENABLED, AliasStatus.DISABLED])
                                                     .required()
                                      });
